<template>
  <el-dialog width="880px" append-to-body :before-close="handleClose" :visible.sync="dialog">
    <h2 v-if="formData.State == 0 && formData.Statename == '' || formData.State == 1" style="text-align: center;">
      {{ $t('All.年益实业股份有限公司') }}</h2>
    <h3 v-if="formData.State == 0 && formData.Statename == '' || formData.State == 1" style="text-align: center;">
      {{ $t('All.实验送测单') }}</h3>
    <h4 v-if="formData.State == 0 && formData.Statename == '' || formData.State == 1" style="text-align: center;">
      {{ $t('All.通知单号') }}：{{ formData.Numbers }}</h4>
    <h4 v-if="formData.State == 0 && formData.Statename == '' || formData.State == 1" style="text-align: center;">
      {{ $t('All.开单日期') }}：{{ formData.Date }}</h4>
    <el-form ref="elForm" v-if="formData.State == 0 && formData.Statename == '' || formData.State == 1"
      :model="formData" :rules="rules" style="max-width: 800px;margin: auto;" size="medium" label-width="90px"
      label-position="top">
      <el-form-item :label="$t('All.部门')" prop="Product_item">
        <el-select v-model="formData.Department" allow-create filterable placeholder="请选择部门" :style="{ width: '100%' }">
          <el-option v-for="item in $PublicJs.testdepartment" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('All.工单号')" prop="Gongdan">
        <el-input v-model="formData.Gongdan" :placeholder="$t('All.IQC请输入采购单号工程QE请输入项目号其它QC请输入工单号')" clearable
          :style="{ width: '100%' }">
        </el-input>
      </el-form-item>
      <el-form-item :label="$t('All.品名料号')" prop="Product_item">
        <el-input v-model="formData.Product_item" :placeholder="$t('All.请输入品名料号')" clearable :style="{ width: '100%' }">
        </el-input>
      </el-form-item>
      <el-form-item :label="$t('All.客户厂商')" prop="Customer_manufacturer">
        <el-input v-model="formData.Customer_manufacturer" :placeholder="$t('All.请输入客户厂商')" clearable
          :style="{ width: '100%' }"></el-input>
      </el-form-item>
      <el-form-item :label="$t('All.实验名称')" prop="Experiment_name">
        <el-input v-model="formData.Experiment_name" :placeholder="$t('All.请输入实验名称')" clearable
          :style="{ width: '100%' }">
        </el-input>
      </el-form-item>
      <el-form-item :label="$t('All.样品数量')" prop="Quantity">
        <el-input v-model="formData.Quantity" :placeholder="$t('All.请输入样品数量')" clearable :style="{ width: '100%' }">
        </el-input>
      </el-form-item>
      <el-form-item :label="$t('All.紧急状况')" prop="Urgent_state">
        <el-radio-group v-model="formData.Urgent_state" size="medium">
          <el-radio v-for="(item, index) in Urgent_stateOptions" :key="index" :label="item.value"
            :disabled="item.disabled">{{ item.label }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="$t('All.含卤情况')" prop="Halogen">
        <el-radio-group v-model="formData.Halogen" size="medium">
          <el-radio v-for="(item, index) in HalogenOptions" :key="index" :label="item.value"
            :disabled="item.disabled">{{ item.label }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="$t('All.实验要求条件')" prop="Requirements">
        <el-input v-model="formData.Requirements" type="textarea" style="color: #606266;"
          :placeholder="$t('All.请输入实验要求条件')" :autosize="{ minRows: 4, maxRows: 4 }" :style="{ width: '100%' }"></el-input>
      </el-form-item>
      <el-form-item :label="$t('All.参考文件')">
        <el-upload list-type="picture-card" :action="`${$Upload}/api/Laboratory/File_user_upload/`" :data="filedata"
          :on-success="handsuccess" :file-list="fileList">
          <i slot="default" class="el-icon-plus"></i>
          <div slot="file" slot-scope="{file}">
            <img class="el-upload-list__item-thumbnail" :src="xsimg(file)" :alt="file.name">
            <span class="el-upload-list__item-actions" :alt="file.name">
              <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                <i class="el-icon-zoom-in"></i>
              </span>
              <span class="el-upload-list__item-delete" @click="handleDownload(file)">
                <i class="el-icon-download"></i>
              </span>
              <span class="el-upload-list__item-delete" @click="handleRemove(file)">
                <i class="el-icon-delete"></i>
              </span>
            </span>
            <p>{{ file.name }}</p>
          </div>
          <el-dialog :visible.sync="dialogVisible" append-to-body>
            <img width="100%" :src="dialogImageUrl" alt="">
          </el-dialog>
        </el-upload>
      </el-form-item>
      <el-form-item :label="this.$t('All.备注')">
        <el-input v-model="formData.Remarks" type="textarea" :placeholder="$t('All.请输入备注')"
          :autosize="{ minRows: 4, maxRows: 4 }" :style="{ width: '100%' }"></el-input>
      </el-form-item>
      <el-form-item size="small" style="text-align: right;">
        <el-button type="primary" @click="submitForm">{{ $t('All.提交') }}</el-button>
        <el-button @click="resetForm">{{ $t('All.重置') }}</el-button>
      </el-form-item>
    </el-form>
    <div style="text-align: right;">
      <el-button size="small" style="text-align: right;" v-if="formData.State != 10 && formData.Statename !== ''"
        type="primary" v-print="printObj1">{{ $t('All.打印送测单') }}</el-button>
      <el-button size="small" style="text-align: right;"
        v-if="formData.State > 2 && formData.External == 2 && formData.State == 10" type="primary"
        @click="printrxf(formData.Numbers)">{{ $t('All.打印报告') }}</el-button>
      <el-button size="small" style="text-align: right;"
        v-if="formData.State > 2 && formData.External == 0 && formData.State == 10" type="primary"
        @click="print(formData.Numbers)">{{ $t('All.打印报告') }}</el-button>
    </div>
    <div :id="songid" v-if="formData.State != 10 && formData.Statename !== ''" style="width:794px;margin: auto;">
      <img src="../../../assets/Img/NIENYI-2.png" :alt="$t('All.年益集团')"
        style="width: 100px;position: absolute;margin-top: 0px;margin-left: 150px;z-index: -1;">
      <h2 style="text-align: center;">{{ this.$t('system.company') }}</h2>
      <h3 style="text-align: center;line-height: 1;">{{ $t('All.实验送测单') }}</h3>
      <h4 style="text-align: center;line-height: 1;">{{ $t('All.通知单号') }}：{{ formData.Numbers }}</h4>
      <h4 style="text-align: center;line-height: 1;" class="zhuangtai">{{ $t('All.当前状态') }}：{{ formData.Statename }}</h4>
      <table border="1" style="width: 98%; margin: 0 auto;" class="table-layout">
        <tr>
          <td class="tdd" style="width: 25%;text-align: center;">
            {{ $t('All.申请人') }}
          </td>
          <td class="tdd" style="width: 25%;text-align: center;">
            {{ $t('All.部门') }}
          </td>
          <td class="tdd" style="width: 25%;text-align: center;">
            {{ $t('All.通知日期') }}
          </td>
          <td class="tdd" style="width: 25%;text-align: center;">
            {{ $t('All.完成日期') }}
          </td>
        </tr>
        <tr>
          <td class="tdd" style="width: 25%;text-align: center;">
            {{ formData.Applicantname }}
          </td>
          <td class="tdd" style="width: 25%;text-align: center;">
            {{ formData.Department }}
          </td>
          <td class="tdd procedure" style="width: 25%;text-align: center;">
            {{ formData.Date }}
          </td>
          <td class="tdd procedure" style="width: 25%;text-align: center;">
            {{ formData.State == 0 || formData.State == 1 ? '' : formData.Estimate }}
          </td>
        </tr>
        <tr>
          <td class="tdd" style="width: 25%;text-align: center;">
            {{ $t('All.客户厂商') }}
          </td>
          <td class="tdd" style="width: 25%;text-align: center;">
            {{ $t('All.品名料号') }}
          </td>
          <td class="tdd" style="width: 25%;text-align: center;">
            {{ $t('All.实验名称') }}
          </td>
          <td class="tdd" style="width: 25%;text-align: center;">
            {{ $t('All.样品数量') }}
          </td>
        </tr>
        <tr>
          <td class="tdd" style="width: 25%;text-align: center;">
            {{ formData.Customer_manufacturer }}
          </td>
          <td class="tdd" style="width: 25%;text-align: center;">
            {{ formData.Product_item }}
          </td>
          <td class="tdd" style="width: 25%;text-align: center;">
            {{ formData.Experiment_name }}
          </td>
          <td class="tdd" style="width: 25%;text-align: center;">
            {{ formData.Quantity }}
          </td>
        </tr>
        <tr>
          <td class="tdd" style="width: 25%;text-align: center;">
            {{ $t('All.紧急状况') }}
          </td>
          <td class="tdd" style="width: 25%;text-align: center;">
            {{ formData.Urgent_state }}
          </td>
          <td class="tdd" style="width: 25%;text-align: center;">
            {{ $t('All.含卤情况') }}
          </td>
          <td class="tdd" style="width: 25%;text-align: center;">
            {{ formData.Halogen }}
          </td>
        </tr>
        <tr>
          <td class="tdd" style="width: 25%;text-align: center;">
            {{ $t('All.工单号') }}
          </td>
          <td class="tdd" style="width: 25%;text-align: center;">
            {{ formData.Gongdan }}
          </td>
          <td class="tdd" style="width: 25%;text-align: center;">
          </td>
          <td class="tdd" style="width: 25%;text-align: center;">
          </td>
        </tr>
        <tr>
          <td class="tdd" style="width: 25%;text-align: center;">
            {{ $t('All.实验要求条件') }}
          </td>
          <td class="tdd" style="width: 75%;" colspan="3">
            <el-input class="session-file__form" disabled v-model="formData.Requirements" type="textarea"
              :autosize="{ minRows: 4, maxRows: 4 }" :style="{ width: '100%' }"></el-input>
          </td>
        </tr>
        <tr valign="top">
          <td class="tdd" style="width: 25%; height: 360px; text-align: center;">
            {{ $t('All.参考文件') }}
          </td>
          <td class="tdd" :class="!isdata && formData.State == 1 ? '' : 'uploaddise'" style="width: 75%;" colspan="3">
            <el-upload :disabled="isdata" list-type="picture-card"
              :action="`${$Upload}/api/Laboratory/File_user_upload/`" :data="filedata" :on-success="handsuccess"
              :file-list="fileList">
              <i slot="default" class="el-icon-plus"></i>
              <div slot="file" slot-scope="{file}">
                <img class="el-upload-list__item-thumbnail" :src="xsimg(file)" :alt="file.name">
                <span class="el-upload-list__item-actions" :alt="file.name">
                  <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                    <i class="el-icon-zoom-in"></i>
                  </span>
                  <span class="el-upload-list__item-delete" @click="handleDownload(file)">
                    <i class="el-icon-download"></i>
                  </span>
                  <span v-show="!isdata && formData.State == 1" class="el-upload-list__item-delete"
                    @click="handleRemove(file)">
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
                <p>{{ file.name }}</p>
              </div>
              <el-dialog :visible.sync="dialogVisible" append-to-body>
                <img width="100%" :src="dialogImageUrl" alt="">
              </el-dialog>
            </el-upload>
          </td>
        </tr>
        <tr>
          <td class="tdd" style="width: 25%;text-align: center;">
            {{ $t('All.备注') }}
          </td>
          <td class="tdd" style="width: 75%;" colspan="3">
            <el-input class="session-file__form" disabled v-model="formData.Remarks" type="textarea"
              :autosize="{ minRows: 4, maxRows: 4 }" :style="{ width: '100%' }"></el-input>
          </td>
        </tr>
      </table>
      <table style="width: 90%;border-top: 0; margin: 0 auto;" class="table-layout">
        <tr>
          <td class="tdd" style="text-align: right;" colspan="6">
            NYS1008-02-C
          </td>
        </tr>
      </table>
    </div>
    <div :id="baoid" style="width:794px;margin: auto;" v-if="isxrf && formData.State == 10">
      <img src="../../../assets/Img/NIENYI-2.png" :alt="$t('All.年益集团')"
        style="width: 100px;position: absolute;margin-top: 0px;margin-left: 150px;">
      <h2 style="text-align: center;">{{ $t('All.年益实业股份有限公司') }}</h2>
      <h3 style="text-align: center;line-height: 1;">{{ $t('All.实验报告') }}(Test report)</h3>
      <h4 style="text-align: center;line-height: 1;">{{ $t('All.通知单号') }}：{{ formData.Numbers }}</h4>
      <span style="width: 100px;position: absolute;margin-top: -20px;margin-left: 690px;">{{ formData.Gongdan }}</span>
      <table border="1" style="width: 98%;  margin: 0 auto;" class="table-layout">
        <tr>
          <td style="width: 26%;text-align: center;" colspan="1">
            <p style="margin: 0;line-height: 1.2;overflow:hidden;white-space:nowrap;">{{ $t('All.客户厂商') }}<br>Customer</p>
          </td>
          <td class="tdd zhadminadd" style="width: 25%;text-align: center;" colspan="2">
            <span>{{ form.Customer_manufacturer }}</span>
          </td>
          <td class="tdd" style="width: 25%;text-align: center;">
            <p style="margin: 0;line-height: 1.2;">{{ $t('All.品名规格') }}<br>ITEM</p>
          </td>
          <td class="tdd zhadminadd" style="width: 25%;text-align: center;" colspan="2">
            <span>{{ form.Product_item }}</span>
          </td>
        </tr>
        <tr>
          <td class="tdd" style="text-align: center;">
            <p style="margin: 0;line-height: 1.2;">{{ $t('All.试验项目') }}<br>Test item</p>
          </td>
          <td class="tdd zhadminadd" colspan="2" style="text-align: center;">
            <span>{{ form.Project }}</span>
          </td>
          <td class="tdd" style="text-align: center;">
            <p style="margin: 0;line-height: 1.2;">{{ $t('All.试验日期') }}<br>Test date</p>
          </td>
          <td class="tdd procedure zhadminadd" colspan="2" style="text-align: center;">
            <span>{{ form.Date }}</span>
          </td>
        </tr>
        <tr>
          <td class="tdd" style="text-align: center;">
            <p style="margin: 0;line-height: 1.2;overflow:hidden;white-space:nowrap;">{{ $t('All.实验室环境') }}<br>Test
              Environment</p>
          </td>
          <td style="text-align: center;" class="tdd" colspan="5">
            {{ $t('All.温度') }}/Temperature：{{ form.Temperature }}°C，
            {{ $t('All.湿度') }}/Humidity：{{ form.Humidity }}%RH
          </td>
        </tr>
        <tr>
          <td class="tdd" style="text-align: center;">
            <p style="margin: 0;line-height: 1.2;overflow:hidden;white-space:nowrap;">{{ $t('All.仪器设备') }}<br>Equipment
            </p>
          </td>
          <td style="text-align: left;" class="tdd" colspan="5">
            <p style="margin: 0;line-height: 1.2;">
              {{ $t('All.编号') }}/Number：{{ form.InstrumentNumbers }}<br>{{ $t('All.名称') }}/Name：{{ form.Instrument }}</p>
          </td>
        </tr>
        <tr>
          <td class="tdd" style="text-align: center;">
            <p style="margin: 0;line-height: 1.2;">{{ $t('All.试验条件') }}<br>Test condition</p>
          </td>
          <td class="tdd zhadminadd" colspan="5">
            <el-input class="session-file__form" disabled v-model="form.Test_conditions" type="textarea"
              :placeholder="$t('All.请输入试验条件')" :autosize="{ minRows: 4, maxRows: 4 }" :style="{ width: '100%' }"></el-input>
          </td>
        </tr>
        <tr>
          <td class="tdd" style="text-align: center;" colspan="6">
            <p style="margin: 0;line-height: 1.2;">{{ $t('All.测试状况及实验内容') }}<br>Test Status and Contents</p>
          </td>
        </tr>
        <tr valign="top">
          <td colspan="6">
            <div>
              <Toolbar v-if="false" style="border-bottom: 1px solid #ccc" :editor="editor"
                :defaultConfig="toolbarConfig" :mode="mode" />
              <Editor style="height: 480px; overflow: hidden;" disabled v-model="form.Htmltext"
                :defaultConfig="editorConfig" :mode="mode" @onCreated="onCreated" />
            </div>
          </td>
          <!--
              <td class="editor-content-view" style="height: 480px !important;overflow: hidden;" v-if="((formData.State == 2 || formData.State == 6) && $store.state.Login.User == formData.Receiver)" v-html="form.Htmltext" colspan="6">
            </td> -->
        </tr>
        <tr>
          <td class="tdd" style="text-align: center;">
            <p style="margin: 0;line-height: 1.2;">{{ $t('All.最终判定') }}<br>Final judgment</p>
          </td>
          <td style="text-align: center;" class="tdd" colspan="5">
            <span>{{ form.Determine }}</span>
          </td>
        </tr>
        <tr>
          <td class="tdd" style="text-align: center;">
            <p style="margin: 0;line-height: 1.2;">{{ $t('All.备注') }}<br>Remarks</p>
          </td>
          <td class="tdd zhadminadd" colspan="5">
            <el-input class="session-file__form" disabled v-model="form.Opinion" type="textarea"
              :autosize="{ minRows: 4, maxRows: 4 }" :style="{ width: '100%' }"></el-input>
          </td>
        </tr>
      </table>
      <table style="width: 90%;border-top: 0; margin: 0 auto;" class="table-layout">
        <tr>
          <td class="tdd" style="width: 16%;text-align: center;">
            {{ $t('All.核准') }}
          </td>
          <td class="tdd" style="width: 16%;text-align: center;">
            {{ form.Approvername }}
          </td>
          <td class="tdd" style="width: 16%;text-align: center;">
            {{ $t('All.审核') }}
          </td>
          <td class="tdd" style="width: 16%;text-align: center;">
            {{ form.Reviewername }}
          </td>
          <td class="tdd" style="width: 16%;text-align: center;">
            {{ $t('All.制表') }}
          </td>
          <td class="tdd" style="width: 16%;text-align: center;">
            {{ form.Receivername }}
          </td>
        </tr>
        <tr>
          <td class="tdd" style="text-align: right;" colspan="6">
            NYS1009-04-B
          </td>
        </tr>
      </table>
      <el-upload style="padding-left: 5%;" class="uploaddise" v-if="fileList4.length > 0" disabled
        list-type="picture-card" :action="`${$Upload}/api/Laboratory/File_user_upload/`" :file-list="fileList4">
        <i slot="default" class="el-icon-plus"></i>
        <div slot="file" slot-scope="{file}">
          <img class="el-upload-list__item-thumbnail" :src="xsimg(file)" :alt="file.name">
          <span class="el-upload-list__item-actions" :alt="file.name">
            <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
              <i class="el-icon-zoom-in"></i>
            </span>
            <span class="el-upload-list__item-delete" @click="handleDownload(file)">
              <i class="el-icon-download"></i>
            </span>
            <span v-show="(formData.State == 2 || formData.State == 6) && $store.state.Login.User == formData.Receiver"
              class="el-upload-list__item-delete" @click="handleRemove4(file)">
              <i class="el-icon-delete"></i>
            </span>
          </span>
          <p>{{ file.name }}</p>
        </div>
      </el-upload>
    </div>
    <Flowxrftable :PrintObj3="PrintObj3" :id="baoid" v-if="formData.External == 2 && !isxrf" :Numbers="formData.Numbers"
      :FormData="formData"></Flowxrftable>
  </el-dialog>
</template>
<script>
import { mapMutations } from 'vuex'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import Flowxrftable from '@/components/Test/Upload/Flowxrftable.vue'
export default {
  components: { Editor, Toolbar, Flowxrftable },
  props: ['Test_Item', 'Test_Numbers', 'formxin', 'Test_Index', 'Test_Supplier', 'Test_Product_name', 'Texttablebool', 'Texttablefunadd'],
  data () {
    return {
      form: {},
      formData: {
        Date: '',
        Numbers: '',
        Product_item: '',
        Customer_manufacturer: '',
        Experiment_name: '',
        Quantity: '',
        Urgent_state: this.$t('All.普通'),
        Requirements: '',
        Remarks: '',
        Applicant: this.$store.state.Login.User,
        Applicantname: this.$store.state.Login.Username,
        Factory: this.$store.state.Login.Factory,
        Receiver: '',
        Receivername: '',
        Reviewer: '',
        Reviewername: '',
        External: 0,
        Approver: '',
        Approvername: '',
        Department: '',
        Filename: '',
        Fileurl: '',
        State: 0,
        Theredays: 0,
        Statename: '',
        Nextname: this.$t('All.等待实验室接收'),
        IQCNumbers: '',
        Gongdan: '',
        Outreachtesting: 2
      },
      rules: {
        Date: [{
          required: true,
          message: this.$t('All.请输入开单日期'),
          trigger: 'blur'
        }],
        Numbers: [{
          required: true,
          message: this.$t('All.请输入通知单号'),
          trigger: 'blur'
        }],
        Product_item: [{
          required: true,
          message: this.$t('All.请输入品名料号'),
          trigger: 'blur'
        }],
        Customer_manufacturer: [{
          required: true,
          message: this.$t('All.请输入客户厂商'),
          trigger: 'blur'
        }],
        Experiment_name: [{
          required: true,
          message: this.$t('All.请输入实验名称'),
          trigger: 'blur'
        }],
        Gongdan: [{
          required: true,
          message: this.$t('All.IQC请输入采购单号工程QE请输入项目号其它QC请输入工单号'),
          trigger: 'blur'
        }],
        Quantity: [{
          required: true,
          message: this.$t('All.请输入样品数量且只能输入数字'),
          trigger: 'blur'
        }, {
          pattern: /^\d+(\.\d+)?$/,
          message: this.$t('All.只能输入数字'),
          trigger: 'blur'
        }],
        Urgent_state: [{
          required: true,
          message: this.$t('All.紧急状况不能为空'),
          trigger: 'change'
        }],
        Halogen: [{
          required: true,
          message: this.$t('All.含卤情况不能为空'),
          trigger: 'change'
        }],
        Requirements: [{
          required: true,
          message: this.$t('All.请输入实验要求条件'),
          trigger: 'blur'
        }]
      },
      Urgent_stateOptions: [{
        label: this.$t('All.特急'),
        value: this.$t('All.特急')
      }, {
        label: this.$t('All.急'),
        value: this.$t('All.急')
      }, {
        label: this.$t('All.普通'),
        value: this.$t('All.普通')
      }],
      HalogenOptions: [{
        label: 'HF',
        value: 'HF'
      }, {
        label: 'HSF',
        value: 'HSF'
      }],
      fileList: [],
      fileList4: [],
      filedata: {
        Numbers: '',
        Type: 0,
        User: this.$store.state.Login.User,
        Username: this.$store.state.Login.Username,
        Factory: this.$store.state.Login.Factory
      },
      dialogVisible: false,
      dialogImageUrl: '',
      dialog: true,
      Istable: false,
      isdata: false,
      editor: null,
      toolbarConfig: {},
      printObj1: {
        id: 'song' + this.Test_Numbers, // 这里是要打印元素的ID
        popTitle: this.$t('All.年益实业股份有限公司'), // 打印的标题
        extraCss: '', // 打印可引入外部的一个 css 文件
        extraHead: '' // 打印头部文字
      },
      printObj2: {
        id: 'printTest', // 这里是要打印元素的ID
        popTitle: this.$t('All.年益实业股份有限公司'), // 打印的标题
        extraCss: '', // 打印可引入外部的一个 css 文件
        extraHead: '' // 打印头部文字
      },
      printObj3: {
        id: 'bao' + this.Test_Numbers, // 这里是要打印元素的ID
        popTitle: this.$t('All.年益实业股份有限公司'), // 打印的标题
        extraCss: '', // 打印可引入外部的一个 css 文件
        extraHead: '' // 打印头部文字
      },
      editorConfig: {
        placeholder: '',
        readOnly: true, // 只读、不可编辑
        scroll: false,
        // 所有的菜单配置，都要在 MENU_CONF 属性下
        MENU_CONF: {
          uploadImage: {
            fieldName: 'file',
            server: `${this.$Upload}/api/Laboratory/File_user_fuupload/`,
            meta: {
              Numbers: '123123',
              Type: 2,
              User: this.$store.state.Login.User,
              Username: this.$store.state.Login.Username,
              Factory: this.$store.state.Login.Factory
            }
          },
          uploadVideo: {
            fieldName: 'file',
            server: `${this.$Upload}/api/Laboratory/File_user_fuuploadvideo/`,
            meta: {
              Numbers: '123123',
              Type: 2,
              User: this.$store.state.Login.User,
              Username: this.$store.state.Login.Username,
              Factory: this.$store.state.Login.Factory
            },
            // 单个文件的最大体积限制，默认为 10M
            maxFileSize: 500 * 1024 * 1024, // 5M
            // 最多可上传几个文件，默认为 5
            maxNumberOfFiles: 10
          }
        }
      },
      mode: 'default', // or 'simple'
      isxrf: true,
      baoid: '',
      songid: ''
    }
  },
  computed: {},
  watch: {},
  created () {
    if (this.Test_Numbers === '' || this.Test_Numbers === undefined) {
      this.LaCount()
    } else {
      this.getLaboratorydata(this.Test_Numbers)
    }
    this.baoid = 'bao' + this.Test_Numbers
    this.songid = 'song' + this.Test_Numbers
  },
  beforeDestroy () {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  },
  mounted () { },
  methods: {
    PrintObj3 (row) {
      this.printObj3.id = row
    },
    handleClose (done) {
      this.$confirm(this.$t('All.确认关闭'), {
        confirmButtonText: this.$t('All.确认'),
        cancelButtonText: this.$t('All.取消'),
        type: 'warning'
      })
        .then(_ => {
          this.Texttablebool()
        })
        .catch(_ => { })
    },
    ...mapMutations(['TabComponentFun']),
    async LaCount () {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post('/api/Laboratory/LaCount', this.$store.state.Login)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(res.response)
        }
        loadingInstance.close()
        this.formData.Numbers = res.response
        this.formData.Date = this.getCurrentTime()
        this.filedata.Numbers = res.response
        this.formData.Department = this.$store.state.Login.Department
        this.formData.Product_item = this.Test_Product_name
        this.formData.Customer_manufacturer = this.Test_Supplier
        this.formData.Experiment_name = this.Test_Item.Title
        this.formData.IQCNumbers = this.formxin.Numbers
        this.formData.Requirements = this.Test_Item.Benchmark
        this.formData.Gongdan = this.formxin.Workorder
        this.LaUserGetAllFiles(res.response)
      } catch (error) {
        loadingInstance.close()
      }
    },
    getCurrentTime () {
      // 获取当前时间并打印
      const yy = new Date().getFullYear()
      const mm = new Date().getMonth() + 1
      const dd = new Date().getDate()
      // 时
      const hour = new Date().getHours()
      // 分
      const minute = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes()
      // 秒
      const second = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds()

      const gettime = yy + '-' + mm + '-' + dd + ' ' + hour + ':' + minute + ':' + second
      return gettime
    },
    async handsuccess (response) {
      const data = {
        name: '',
        url: ''
      }
      if (response.status !== 200) return this.$message.error(this.$t('All.图片上传失败'))
      data.name = response.name
      data.url = response.url
      this.fileList.splice(0, 0, data)
    },
    async handleRemove (file) {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        var row = {
          Name: file.name,
          Type: this.Type,
          Numbers: this.filedata.Numbers
        }
        const { data: res } = await this.$http.post('/api/Laboratory/File_user_del', row)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(this.$t('All.数据异常'))
        }
        loadingInstance.close()
        this.fileList.some((item, i) => {
          if (item.name === file.name) {
            this.fileList.splice(i, 1)
          }
        })
        this.$message.success(`${this.$t('All.删除')}${file.name}${this.$t('All.成功')}`)
      } catch (error) {
        this.$message.error(error)
        loadingInstance.close()
      }
    },
    async LaUserGetAllFiles (row) {
      try {
        const { data: res } = await this.$http.get('/api/Laboratory/LaUserGetAllFiles?Number=' + row + '&Factory=' + this.$store.state.Login.Factory)
        if (res.status !== 200) return
        for (let index = 0; index < res.response.length; index++) {
          if (res.response[index].Type === 0) {
            this.fileList.splice(0, 0, res.response[index])
          } else if (res.response[index].Type === 3) {
            this.fileList4.splice(0, 0, res.response[index])
          }
        }
      } catch (error) {
        alert(error)
      }
    },
    async getLaboratorydata (row) {
      try {
        const { data: res } = await this.$http.post('/api/Laboratory/getLaboratorydata?Numbers=' + row)
        if (res.status !== 200) return
        this.formData = res.response
        this.LaUserGetAllFiles(row)
        if (res.response.State > 1) {
          this.Reporty(res.response.Numbers)
        }
      } catch (error) {
        alert(error)
      }
    },
    onCreated (editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    xsimg (val) {
      var index = val.name.lastIndexOf('.')
      const obj = val.name.substring(index + 1, val.name.length)
      const objj = obj.toUpperCase()
      switch (objj) {
        case 'JPEG':
          return val.url
        case 'BMP':
          return val.url
        case 'TIFF':
          return val.url
        case 'GIF':
          return val.url
        case 'PNG':
          return val.url
        case 'JPG':
          return val.url
        case 'PDF':
          return require('@/assets/Img/pdf.jpg')
        case 'XLS':
          return require('@/assets/Img/excle.jpg')
        case 'XLSX':
          return require('@/assets/Img/excle.jpg')
        case 'RAR':
          return require('@/assets/Img/rar.jpeg')
        case 'ZIP':
          return require('@/assets/Img/rar.jpeg')
        default:
          return require('@/assets/Img/nullimg.jpeg')
      }
    },
    submitForm () {
      this.formData.State = 0
      this.$refs.elForm.validate(async valid => {
        if (!valid) return this.$message.error(this.$t('All.请完善表单'))
        // TODO 提交表单
        const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
        try {
          const { data: res } = await this.$http.post('/api/Laboratory/Laboratryadd', this.formData)
          if (res.status !== 200) {
            loadingInstance.close()
            return this.$message.error(res.response)
          }
          loadingInstance.close()
          this.Texttablefunadd(this.Test_Index, this.formData.Numbers)
          this.Texttablebool()
        } catch (error) {
          loadingInstance.close()
          alert(this.$t('All.网络异常'))
        }
      })
    },
    resetForm () {
      this.$refs.elForm.resetFields()
    },
    async Reporty (row) {
      try {
        const { data: res } = await this.$http.post('/api/Laboratory/Reporty?Numbers=' + row)
        if (res.status !== 200) return
        if (res.response !== null) {
          const instsdnub = res.response.InstrumentNumbers.split(',')
          const instrument = res.response.Instrument.split(',')
          const datalist = []
          for (let index = 0; index < instsdnub.length; index++) {
            datalist.push({
              Numbers: instsdnub[index],
              Name: instrument[index]
            })
          }
          this.Sheb = datalist
          this.form = res.response
        } else {
          this.isxrf = false
        }
      } catch (error) {
        alert(error)
      }
    },
    handlePictureCardPreview (file) {
      if (this.$store.state.Login.Factory === 'All' || this.$store.state.Login.Factory === undefined) {
        window.open(this.$Upload + file.url)
      } else {
        window.open(file.url)
      }
    },
    handleDownload (file) {
      var url = file.url
      var alink = document.createElement('a')
      fetch(url).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
        alink.href = URL.createObjectURL(blob)
        alink.download = file.name
        document.body.appendChild(alink)
        alink.click()
      })
    },
    print (row) {
      this.$message.success(this.$t('All.如打不开') + '，' + this.$t('All.请关闭弹窗拦截'))
      window.open(`https://qc.nienyi.cn/#/Flowtable/${row}`, '_blank')
    },
    printrxf (row) {
      this.$message.success(this.$t('All.如打不开') + '，' + this.$t('All.请关闭弹窗拦截'))
      window.open(`https://qc.nienyi.cn/#/Flowxrftable/${row}`, '_blank')
    }
  }
}

</script>
<style>
@page {
  margin: 2mm;
  /* this affects the margin in the printer settings */
  display: block;
  width: 100%;
  overflow: hidden;
}

#printTest table {
  table-layout: auto !important;
}

#printTest .el-table__header-wrapper .el-table__header {
  width: 100% !important;
  border: solid 1px #f2f2f2;
}

#printTest .el-table__body-wrapper .el-table__body {
  width: 100% !important;
}

#printTest #pagetable table {
  table-layout: fixed !important;
}

.procedure {
  word-wrap: break-word;
}
</style>
