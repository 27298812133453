import router from '../../router/index'
import i18n from '../../i18n'

export default {
  department: [{
    value: 'FQC',
    label: 'FQC'
  }, {
    value: 'IQC',
    label: 'IQC'
  }, {
    value: 'IPQC',
    label: 'IPQC'
  }, {
    value: 'OQC',
    label: 'OQC'
  }, {
    value: 'CK',
    label: i18n.t('PublicJs.仓库')
  }, {
    value: 'Test',
    label: i18n.t('PublicJs.实验室')
  }, {
    value: 'GC',
    label: i18n.t('PublicJs.工程')
  }, {
    value: 'SCYB',
    label: i18n.t('PublicJs.生产一部')
  }, {
    value: 'CT',
    label: i18n.t('PublicJs.铜线')
  }, {
    value: 'CY',
    label: i18n.t('PublicJs.冲压') + '/' + i18n.t('PublicJs.注塑')
  }, {
    value: 'ZL',
    label: i18n.t('PublicJs.制粒')
  }, {
    value: 'SCEB',
    label: i18n.t('PublicJs.生产二部')
  }, {
    value: 'SCSANB',
    label: i18n.t('PublicJs.生产三部')
  }, {
    value: 'SCSIB',
    label: i18n.t('PublicJs.生产四部')
  }, {
    value: 'SCWUB',
    label: i18n.t('PublicJs.生产五部')
  }, {
    value: 'SCLIUB',
    label: i18n.t('PublicJs.生产六部')
  }, {
    value: 'NONE',
    label: i18n.t('PublicJs.其它')
  }],
  testdepartment: [{
    value: 'FQC',
    label: 'FQC'
  }, {
    value: 'IQC',
    label: 'IQC'
  }, {
    value: 'IPQC',
    label: 'IPQC'
  }, {
    value: 'OQC',
    label: 'OQC'
  }, {
    value: '仓库',
    label: i18n.t('PublicJs.仓库')
  }, {
    value: '实验室',
    label: i18n.t('PublicJs.实验室')
  }, {
    value: '工程',
    label: i18n.t('PublicJs.工程')
  }, {
    value: '电线品管',
    label: i18n.t('PublicJs.电线品管')
  }, {
    value: '注塑',
    label: i18n.t('PublicJs.注塑')
  }, {
    value: 'SCYB',
    label: i18n.t('PublicJs.生产一部')
  }, {
    value: 'SCEB',
    label: i18n.t('PublicJs.生产二部')
  }, {
    value: 'SCSANB',
    label: i18n.t('PublicJs.生产三部')
  }, {
    value: 'SCSIB',
    label: i18n.t('PublicJs.生产四部')
  }, {
    value: 'SCWUB',
    label: i18n.t('PublicJs.生产五部')
  }, {
    value: 'SCLIUB',
    label: i18n.t('PublicJs.生产六部')
  }, {
    value: '其它',
    label: i18n.t('PublicJs.其它')
  }],
  factory: [{
    value: 'TC',
    label: i18n.t('PublicJs.太仓厂')
  }, {
    value: 'ZH',
    label: i18n.t('PublicJs.珠海厂')
  }, {
    value: 'TY',
    label: i18n.t('PublicJs.腾耀厂')
  }, {
    value: 'VN',
    label: i18n.t('All.越南厂')
  }],
  jurisdiction: [{
    value: 0,
    label: i18n.t('PublicJs.全厂区管理员')
  }, {
    value: 1,
    label: i18n.t('PublicJs.厂区管理员')
  }, {
    value: 3,
    label: i18n.t('PublicJs.主管')
  }, {
    value: 4,
    label: i18n.t('PublicJs.班长')
  },
  {
    value: 5,
    label: i18n.t('PublicJs.文员')
  },
  {
    value: 6,
    label: i18n.t('PublicJs.检验员')
  },
  {
    value: 8,
    label: i18n.t('PublicJs.员工')
  },
  {
    value: 7,
    label: i18n.t('PublicJs.分包员')
  },
  {
    value: 9,
    label: i18n.t('PublicJs.物料员')
  }],
  Determinetype: [{
    value: 0,
    label: i18n.t('PublicJs.自检')
  }, {
    value: 1,
    label: i18n.t('PublicJs.实验室')
  }],
  Frequency: [{
    value: 0,
    label: i18n.t('PublicJs.每批')
  }, {
    value: 30,
    label: i18n.t('PublicJs.每月')
  }, {
    value: 90,
    label: i18n.t('PublicJs.每季')
  }, {
    value: 180,
    label: i18n.t('PublicJs.半年')
  }, {
    value: 360,
    label: i18n.t('PublicJs.一年')
  }],
  Frequencyfun: function (row) {
    var jurisdiction
    switch (row) {
      case 0:
        jurisdiction = i18n.t('PublicJs.每批')
        break
      case 30:
        jurisdiction = i18n.t('PublicJs.每月')
        break
      case 90:
        jurisdiction = i18n.t('PublicJs.每季')
        break
      case 180:
        jurisdiction = i18n.t('PublicJs.半年')
        break
      case 360:
        jurisdiction = i18n.t('PublicJs.一年')
        break
      default:
        jurisdiction = row
        break
    }
    return jurisdiction
  },
  Eixt: function () {
    router.push('/Login')
  },
  JurisdictionDepartment: function (row) {
    var jurisdiction
    switch (row) {
      case 'FQC':
        jurisdiction = 'FQC'
        break
      case 'IQC':
        jurisdiction = 'IQC'
        break
      case 'IPQC':
        jurisdiction = 'IPQC'
        break
      case 'OQC':
        jurisdiction = 'OQC'
        break
      case 'CK':
        jurisdiction = i18n.t('PublicJs.仓库')
        break
      case 'Test':
        jurisdiction = i18n.t('PublicJs.实验室')
        break
      case 'GC':
        jurisdiction = i18n.t('PublicJs.工程')
        break
      case 'SCEB':
        jurisdiction = i18n.t('PublicJs.生产二部')
        break
      case 'SCYB':
        jurisdiction = i18n.t('PublicJs.生产一部')
        break
      case 'All':
        jurisdiction = i18n.t('PublicJs.全部门')
        break
      default:
        jurisdiction = row
        break
    }
    return jurisdiction
  },
  Jurisdiction: function (row) {
    var jurisdiction
    switch (row) {
      case 0:
        jurisdiction = i18n.t('PublicJs.全厂区管理员')
        break
      case 1:
        jurisdiction = i18n.t('PublicJs.厂区管理员')
        break
      case 3:
        jurisdiction = i18n.t('PublicJs.主管')
        break
      case 4:
        jurisdiction = i18n.t('PublicJs.班长')
        break
      case 8:
        jurisdiction = i18n.t('PublicJs.员工')
        break
      case 6:
        jurisdiction = i18n.t('PublicJs.检验员')
        break
      case 7:
        jurisdiction = i18n.t('PublicJs.分包员')
        break
      case 9:
        jurisdiction = i18n.t('PublicJs.物料员')
        break
      default:
        jurisdiction = i18n.t('PublicJs.暂无权限')
        break
    }
    return jurisdiction
  },
  WUliaotype: function (row) {
    var jurisdiction
    switch (row) {
      case 0:
        jurisdiction = i18n.t('PublicJs.PASS单')
        break
      case 1:
        jurisdiction = i18n.t('PublicJs.一部标识卡')
        break
      case 2:
        jurisdiction = i18n.t('PublicJs.二部标识卡')
        break
      case 3:
        jurisdiction = i18n.t('PublicJs.抽铜标识卡')
        break
      case 4:
        jurisdiction = i18n.t('PublicJs.制粒标识卡')
        break
      case 5:
        jurisdiction = i18n.t('PublicJs.冲压标识卡')
        break
      default:
        jurisdiction = i18n.t('PublicJs.其它标识卡')
        break
    }
    return jurisdiction
  },
  rules: {
    User: [
      { required: true, message: i18n.t('PublicJs.请输入用户名'), trigger: 'blur' }
    ],
    Password: [
      { required: true, message: i18n.t('PublicJs.请输入密码'), trigger: 'blur' },
      { min: 6, max: 15, message: i18n.t('PublicJs.请输入密码'), trigger: 'blur' }
    ],
    Username: [
      { required: true, message: i18n.t('PublicJs.请输入姓名'), trigger: 'blur' }
    ],
    Factory: [
      { required: true, message: i18n.t('PublicJs.厂区未选择'), trigger: 'blur' }
    ],
    Jurisdiction: [
      { required: true, message: i18n.t('PublicJs.权限未选择'), trigger: 'blur' }
    ],
    Department: [
      { required: true, message: i18n.t('PublicJs.部门未选择'), trigger: 'blur' }
    ],
    Wx: [
      { required: true, message: i18n.t('PublicJs.微信未选择'), trigger: 'change' }
    ],
    Wxgroup: [
      { required: true, message: i18n.t('PublicJs.微信群未选择'), trigger: 'change' }
    ]
  },
  State: function (row) {
    var state
    switch (row) {
      case 0:
        state = i18n.t('PublicJs.待审')
        break
      case 1:
        state = i18n.t('PublicJs.驳回')
        break
      case 2:
        state = i18n.t('PublicJs.完结')
        break
      case 3:
        state = i18n.t('PublicJs.暂存')
        break
      case 4:
        state = i18n.t('All.班长核可')
        break
      case 5:
        state = i18n.t('All.主管签核')
        break
      case 6:
        state = i18n.t('All.打印贴纸')
        break
      case 7:
        state = i18n.t('PublicJs.紧急提交')
        break
      case 8:
        state = row
        break
      case 9:
        state = row
        break
      case 10:
        state = row
        break
      case 11:
        state = row
        break
      case 12:
        state = row
        break
      case 13:
        state = row
        break
      default:
        state = row
        break
    }
    return state
  },
  Result: function (row) {
    var state
    switch (row) {
      case 0:
        state = i18n.t('system.yshou')
        break
      case 1:
        state = i18n.t('PublicJs.退货')
        break
      case 2:
        state = i18n.t('PublicJs.特采')
        break
      case 3:
        state = i18n.t('All.重工')
        break
      case 4:
        state = i18n.t('All.报废')
        break
      case 5:
        state = i18n.t('All.紧急结案')
        break
      default:
        state = i18n.t('PublicJs.异常')
        break
    }
    return state
  },
  Zfzhi: function (obj, maxx, minn) {
    var qian = obj.split('±')[0]
    var standard = parseFloat(qian.match(/\d+(\.\d+)?/)) // 分割符号前面的值
    var standard1 = obj.split('±')[1]
    var standard2 = parseFloat(standard1.match(/\d+(\.\d+)?/)) // 分割符号后面的值
    var max = (standard * 10000 + standard2 * 10000) / 10000
    var min = (standard * 10000 - standard2 * 10000) / 10000
    if (maxx <= parseFloat(max) && minn >= parseFloat(min)) {
      return true
    } else {
      return false
    }
  },
  Calculation: function (obj, max, min) {
    if (max === -Infinity) {
      return 'Fail'
    }
    if (obj.search('±') !== -1) {
      if (this.Zfzhi(obj, max, min)) {
        return 'Pass'
      } else {
        return 'Fail'
      }
    }
    if (obj.search('≥') !== -1) {
      if (this.dayudengyu(obj, min)) {
        return 'Pass'
      } else {
        return 'Fail'
      }
    }
    if (obj.search('REF') !== -1) {
      return i18n.t('PublicJs.人工判定')
    }
    if (obj.search('Min') !== -1) {
      var standardmin = obj.split('±')[0]
      standardmin = parseFloat(standardmin.match(/\d+(\.\d+)?/)) // 分割符号前面的值
      if (min >= standardmin) {
        return 'Pass'
      } else {
        return 'Fail'
      }
    }
    if (obj.search('Max') !== -1) {
      var standardmax = obj.split('±')[0]
      standardmax = parseFloat(standardmax.match(/\d+(\.\d+)?/)) // 分割符号前面的值
      if (max <= standardmax) {
        return 'Pass'
      } else {
        return 'Fail'
      }
    }
    if (obj.search('/') !== -1) {
      var standard0 = obj.split('+')[0]
      standard0 = parseFloat(standard0.match(/\d+(\.\d+)?/)) // 分割符号前面的值
      var standard1 = obj.split('+')[1] // 分割符号后面的值
      var standard2 = parseFloat(standard1.match(/\d+(\.\d+)?/)) // 分割符号前面的值
      var standard3 = obj.split('-')[1] // 分割符号后面的值
      standard3 = parseFloat(standard3.match(/\d+(\.\d+)?/)) // 分割符号后面的值
      if (max <= ((parseFloat(standard0) * 10000 + parseFloat(standard2) * 10000) / 10000) && min >= (parseFloat(standard0) * 10000 - parseFloat(standard3) * 10000) / 10000) {
        return 'Pass'
      } else {
        return 'Fail'
      }
    }
    return i18n.t('PublicJs.人工判定')
  },
  UserAddState: function (text, state) {
    if (text === '') return 'primary'
    if (text === state) {
      return 'danger'
    } else {
      return 'success'
    }
  },
  getCurrentTime () {
    // 获取当前时间并打印
    const yy = new Date().getFullYear()
    const mm = new Date().getMonth() + 1
    const dd = new Date().getDate()
    const gettime = yy + i18n.t('All.年') + mm + i18n.t('All.月') + dd + i18n.t('All.日')
    return gettime
  },
  text9Ref (obj) {
    if (obj.search('±') !== -1 || obj.search('Min') !== -1 || obj.search('Max') !== -1 || obj.search('/') !== -1) {
      return false
    }
    return true
  },
  text9 (text9) {
    if (text9) {
      return true
    } else {
      return false
    }
  },
  languageXZ: function (val) {
    i18n.locale = val
    localStorage.setItem('locale', val)
  },
  language: [{
    value: 'zh',
    label: i18n.t('PublicJs.中文')
  }, {
    value: 'en',
    label: 'English'
  }, {
    value: 'vi',
    label: 'ViệtName'
  }],
  Parcss: function (val) {
    const par = val
    for (let index = 0; index < par.length; index++) {
      for (let xl = 0; xl < par.length; xl++) {
        if (par[index].Text === par[xl].Text) {
          if (par[xl].Text6.length > par[index].Text6.length) {
            // 在上一项插入该项
            par.splice(index - 1, 0, par[xl])
            // 删除项
            par.splice(xl + 1, 1)
          } else {
            // 在下一项插入该项
            par.splice(index + 1, 0, par[xl])
            // 删除项
            par.splice(xl + 1, 1)
          }
        }
      }
    }
    return par
  },
  Printer: [{
    value: 'JAN',
    label: i18n.t('PublicJs.一月份'),
    color: '#389664'
  }, {
    value: 'FEB',
    label: i18n.t('PublicJs.二月份'),
    color: '#040404'
  }, {
    value: 'MAR',
    label: i18n.t('PublicJs.三月份'),
    color: '#808205'
  }, {
    value: 'APR',
    label: i18n.t('PublicJs.四月份'),
    color: '#0500fc'
  }, {
    value: 'MAY',
    label: i18n.t('PublicJs.五月份'),
    color: '#969696'
  }, {
    value: 'JUN',
    label: i18n.t('PublicJs.六月份'),
    color: '#d4b20a'
  }, {
    value: 'JUL',
    label: i18n.t('PublicJs.七月份'),
    color: '#7c007e'
  }, {
    value: 'AUG',
    label: i18n.t('PublicJs.八月份'),
    color: '#97cb00'
  }, {
    value: 'SEP',
    label: i18n.t('PublicJs.九月份'),
    color: '#ca9cff'
  }, {
    value: 'OCT',
    label: i18n.t('PublicJs.十月份'),
    color: '#00fefe'
  }, {
    value: 'NOV',
    label: i18n.t('PublicJs.十一月份'),
    color: '#fefe00'
  }, {
    value: 'DEC',
    label: i18n.t('PublicJs.十二月份'),
    color: '#0066cc'
  }],
  Dateselection: function () {
    var date = new Date()
    switch (date.getMonth() + 1) {
      case 1:
        return 'JAN'
      case 2:
        return 'FEB'
      case 3:
        return 'MAR'
      case 4:
        return 'APR'
      case 5:
        return 'MAY'
      case 6:
        return 'JUN'
      case 7:
        return 'JUL'
      case 8:
        return 'AUG'
      case 9:
        return 'SEP'
      case 10:
        return 'OCT'
      case 11:
        return 'NOV'
      case 12:
        return 'DEC'
      default:
    }
  },
  Bskyuefen: function (strdata) {
    var date = new Date(strdata)
    switch (date.getMonth() + 1) {
      case 1:
        return 'A'
      case 2:
        return 'B'
      case 3:
        return 'C'
      case 4:
        return 'D'
      case 5:
        return 'E'
      case 6:
        return 'F'
      case 7:
        return 'G'
      case 8:
        return 'H'
      case 9:
        return 'I'
      case 10:
        return 'J'
      case 11:
        return 'K'
      case 12:
        return 'L'
      default:
        return 'X'
    }
  },
  dayudengyu: function (obj, minn) {
    var standard1 = obj.split('≥')[1]
    var standard2 = parseFloat(standard1.match(/\d+(\.\d+)?/)) // 分割符号后面的值
    var min = standard2
    if (minn >= parseFloat(min)) {
      return true
    } else {
      return false
    }
  },
  // 精确运算 加
  add: function (a, b) {
    var c, d, e
    try {
      c = a.toString().split('.')[1].length
    } catch (f) {
      c = 0
    }
    try {
      d = b.toString().split('.')[1].length
    } catch (f) {
      d = 0
    }
    e = Math.pow(10, Math.max(c, d))
    e = (this.mul(a, e) + this.mul(b, e)) / e
    return e
  },

  // 精确运算 减
  sub: function (a, b) {
    var c, d, e
    try {
      c = a.toString().split('.')[1].length
    } catch (f) {
      c = 0
    }
    try {
      d = b.toString().split('.')[1].length
    } catch (f) {
      d = 0
    }
    e = Math.pow(10, Math.max(c, d))
    e = (this.mul(a, e) - this.mul(b, e)) / e
    return e
  },

  // 精确运算 乘
  mul: function (a, b) {
    var c = 0
    var d = a.toString()
    var e = b.toString()
    try {
      c += d.split('.')[1].length
    } catch (f) { }
    try {
      c += e.split('.')[1].length
    } catch (f) { }
    return Number(d.replace('.', '')) * Number(e.replace('.', '')) / Math.pow(10, c)
  },

  // 精确运算 除
  div: function (a, b) {
    var c; var d; var e = 0
    var f = 0
    try {
      e = a.toString().split('.')[1].length
    } catch (g) { }
    try {
      f = b.toString().split('.')[1].length
    } catch (g) { }
    c = Number(a.toString().replace('.', ''))
    d = Number(b.toString().replace('.', ''))
    c = this.mul(c / d, Math.pow(10, f - e))
    return c
  },
  RecordType: function (row) {
    var jurisdiction
    switch (row) {
      case 0:
        jurisdiction = i18n.t('PublicJs.提交表单')
        break
      case 1:
        jurisdiction = i18n.t('PublicJs.删除表单')
        break
      case 2:
        jurisdiction = i18n.t('PublicJs.修改表单')
        break
      case 3:
        jurisdiction = i18n.t('PublicJs.上传文件')
        break
      case 4:
        jurisdiction = i18n.t('PublicJs.删除文件')
        break
    }
    return jurisdiction
  },
  // 文件转url
  getObjectURL (file) {
    var url = null
    if (window.createObjectURL !== undefined) { // basic
      url = window.createObjectURL(file)
    } else if (window.URL !== undefined) { // mozilla(firefox)
      url = window.URL.createObjectURL(file)
    } else if (window.webkitURL !== undefined) { // webkit or chrome
      url = window.webkitURL.createObjectURL(file)
    }
    return url
  },
  Banbi: [{
    value: i18n.t('FQC.一课一班'),
    label: i18n.t('FQC.一课一班')
  }, {
    value: i18n.t('FQC.一课二班'),
    label: i18n.t('FQC.一课二班')
  }, {
    value: i18n.t('FQC.一课三班'),
    label: i18n.t('FQC.一课三班')
  }, {
    value: i18n.t('FQC.一课四班'),
    label: i18n.t('FQC.一课四班')
  }, {
    value: i18n.t('FQC.一课五班'),
    label: i18n.t('FQC.一课五班')
  }, {
    value: i18n.t('FQC.一课六班'),
    label: i18n.t('FQC.一课六班')
  }, {
    value: i18n.t('FQC.二课一班'),
    label: i18n.t('FQC.二课一班')
  }, {
    value: i18n.t('FQC.二课二班'),
    label: i18n.t('FQC.二课二班')
  }, {
    value: i18n.t('FQC.二课三班'),
    label: i18n.t('FQC.二课三班')
  }, {
    value: i18n.t('FQC.二课四班'),
    label: i18n.t('FQC.二课四班')
  }, {
    value: i18n.t('FQC.二课五班'),
    label: i18n.t('FQC.二课五班')
  }, {
    value: i18n.t('FQC.二课六班'),
    label: i18n.t('FQC.二课六班')
  }, {
    value: i18n.t('FQC.三课一班'),
    label: i18n.t('FQC.三课一班')
  }, {
    value: i18n.t('FQC.三课二班'),
    label: i18n.t('FQC.三课二班')
  }, {
    value: i18n.t('FQC.三课三班'),
    label: i18n.t('FQC.三课三班')
  }, {
    value: i18n.t('FQC.三课四班'),
    label: i18n.t('FQC.三课四班')
  }, {
    value: i18n.t('FQC.三课五班'),
    label: i18n.t('FQC.三课五班')
  }, {
    value: i18n.t('FQC.三课六班'),
    label: i18n.t('FQC.三课六班')
  }, {
    value: i18n.t('FQC.四课一班'),
    label: i18n.t('FQC.四课一班')
  }, {
    value: i18n.t('FQC.四课二班'),
    label: i18n.t('FQC.四课二班')
  }, {
    value: i18n.t('FQC.四课三班'),
    label: i18n.t('FQC.四课三班')
  }, {
    value: i18n.t('FQC.四课四班'),
    label: i18n.t('FQC.四课四班')
  }, {
    value: i18n.t('FQC.四课五班'),
    label: i18n.t('FQC.四课五班')
  }, {
    value: i18n.t('FQC.四课六班'),
    label: i18n.t('FQC.四课六班')
  }, {
    value: i18n.t('FQC.五课一班'),
    label: i18n.t('FQC.五课一班')
  }, {
    value: i18n.t('FQC.五课二班'),
    label: i18n.t('FQC.五课二班')
  }, {
    value: i18n.t('FQC.五课三班'),
    label: i18n.t('FQC.五课三班')
  }, {
    value: i18n.t('FQC.五课四班'),
    label: i18n.t('FQC.五课四班')
  }, {
    value: i18n.t('FQC.五课五班'),
    label: i18n.t('FQC.五课五班')
  }, {
    value: i18n.t('FQC.五课六班'),
    label: i18n.t('FQC.五课六班')
  }, {
    value: i18n.t('FQC.六课一班'),
    label: i18n.t('FQC.六课一班')
  }, {
    value: i18n.t('FQC.六课二班'),
    label: i18n.t('FQC.六课二班')
  }, {
    value: i18n.t('FQC.六课三班'),
    label: i18n.t('FQC.六课三班')
  }, {
    value: i18n.t('FQC.六课四班'),
    label: i18n.t('FQC.六课四班')
  }, {
    value: i18n.t('FQC.六课五班'),
    label: i18n.t('FQC.六课五班')
  }, {
    value: i18n.t('FQC.六课六班'),
    label: i18n.t('FQC.六课六班')
  }],
  /**
   * 组合材料抽样比例, 返回抽样数,及随机箱号
   * @param { Number } num  总箱数
   * @param { Number } nums 入库数
   */
  Samplefun (num, nums, type) {
    /**
     * 抽样总箱数
     */
    let Samplebox = 0
    /**
     * 抽样总数
     */
    let Samplecount = 0
    /**
     * 抽样间隔
     */
    let Samplegap = 0
    /**
     * 抽样箱号数组
     */
    let Boxnumber = []
    /**
     * 抽样箱号数组拼接字符
     */
    let Boxnumberstr = ''
    if (num !== '' && num !== null && num !== undefined) {
      if (num >= 1 && num <= 4) {
        Samplebox = num
      } else if (num >= 5 && num <= 12) {
        Samplebox = Math.ceil(num * 0.4)
      } else if (num >= 13 && num <= 30) {
        Samplebox = Math.ceil(num * 0.25)
      } else if (num >= 31 && num <= 50) {
        Samplebox = Math.ceil(num * 0.18)
      } else if (num >= 51 && num <= 100) {
        Samplebox = Math.ceil(num * 0.15)
      } else if (num >= 101) {
        Samplebox = Math.ceil(num * 0.1)
      } else {
        Samplebox = ''
      }
    }
    if (nums !== '' && nums !== null && nums !== undefined && (type === 0 || type === 1)) {
      if (nums >= 2 && nums <= 8) {
        Samplecount = 2
      } else if (nums >= 9 && nums <= 15) {
        Samplecount = 3
      } else if (nums >= 16 && nums <= 25) {
        Samplecount = 5
      } else if (nums >= 26 && nums <= 50) {
        Samplecount = 8
      } else if (nums >= 51 && nums <= 90) {
        Samplecount = 13
      } else if (nums >= 91 && nums <= 150) {
        Samplecount = 20
      } else if (nums >= 151 && nums <= 280) {
        Samplecount = 32
      } else if (nums >= 281 && nums <= 500) {
        Samplecount = 50
      } else if (nums >= 501 && nums <= 1200) {
        Samplecount = 80
      } else if (nums >= 1201 && nums <= 3200) {
        Samplecount = 125
      } else if (nums >= 3201 && nums <= 10000) {
        Samplecount = 200
      } else if (nums >= 10001 && nums <= 35000) {
        Samplecount = 315
      } else if (nums >= 35001 && nums <= 150000) {
        Samplecount = 500
      } else if (nums >= 150001 && nums <= 500000) {
        Samplecount = 800
      } else if (nums >= 500001) {
        Samplecount = 1250
      } else {
        Samplecount = ''
      }
      if (num !== '' && Samplebox !== '') {
        Samplegap = Math.round(num / Samplebox)
        const result = []
        for (let i = 1; i <= num; i += Samplegap) {
          result.push(i)
        }
        Boxnumber = result
        // // 确保首尾箱号在抽样箱号数组中
        // if (num >= 1) {
        //   // 确保首箱号 1 在数组中
        //   if (!result.includes(1)) {
        //     result.unshift(1)
        //   }
        //   // 确保尾箱号在数组中
        //   if (!result.includes(num)) {
        //     result.push(num)
        //     // 如果抽样箱号长度超过计算得出的抽样箱数，则删除尾箱前一箱
        //     if (Boxnumber.length > Samplebox) {
        //       result.splice(Boxnumber.length - 2, 1)
        //     }
        //   }
        // }
      }
      // var upnumdata = Math.ceil(Samplecount / Samplebox) * Samplebox
      // Samplecount = upnumdata
      // Boxnumberstr = Boxnumber.map(item => `${item}/${Math.ceil(Samplecount / Samplebox)}`).join('，')
      // 实际要抽总数
      var Eachboxcount = Math.ceil(Samplecount / Samplebox)
      // 判断是否全检
      if (this.Samplerequire === 3) {
        Boxnumber = Boxnumber.map(item => `${item}`)
      } else {
        // 实际要抽总数
        // Samplecount = Samplebox * Eachboxcount
        // 最后一箱箱号
        // const lastBoxIndex = Boxnumber.length - 1
        // 按照间隔计算的每箱数量
        // Eachboxcount = Math.floor(Samplecount / Boxnumber.length)
        // const Oversample = Samplecount - (Eachboxcount * Boxnumber.length)
        Boxnumber = Boxnumber.map(item => `${item}/${Eachboxcount}`)
        Samplecount = Eachboxcount * Boxnumber.length
        Samplebox = Boxnumber.length
      }
      Boxnumberstr = Boxnumber.join('，')
    }
    if (nums !== '' && nums !== null && nums !== undefined && type === 2) {
      if (nums >= 2 && nums <= 25) {
        Samplecount = 2
      } else if (nums >= 26 && nums <= 50) {
        Samplecount = 3
      } else if (nums >= 51 && nums <= 90) {
        Samplecount = 5
      } else if (nums >= 91 && nums <= 150) {
        Samplecount = 8
      } else if (nums >= 151 && nums <= 280) {
        Samplecount = 13
      } else if (nums >= 281 && nums <= 500) {
        Samplecount = 20
      } else if (nums >= 501 && nums <= 1200) {
        Samplecount = 32
      } else if (nums >= 1201 && nums <= 3200) {
        Samplecount = 50
      } else if (nums >= 3201 && nums <= 10000) {
        Samplecount = 80
      } else if (nums >= 10001 && nums <= 35000) {
        Samplecount = 125
      } else if (nums >= 35001 && nums <= 150000) {
        Samplecount = 200
      } else if (nums >= 150001 && nums <= 500000) {
        Samplecount = 315
      } else if (nums >= 500001) {
        Samplecount = 500
      } else {
        Samplecount = ''
      }
      if (num !== '' && Samplebox !== '') {
        Samplegap = Math.round(num / Samplebox)
        const result = []
        for (let i = 1; i <= num; i += Samplegap) {
          result.push(i)
        }
        Boxnumber = result
      }
      // var upnumdata2 = Math.ceil(Samplecount / Samplebox) * Samplebox
      // Samplecount = upnumdata2
      // Boxnumberstr = Boxnumber.map(item => `${item}/${Math.ceil(Samplecount / Samplebox)}`).join('，')
      Eachboxcount = Math.ceil(Samplecount / Samplebox)
      // 判断是否全检
      if (this.Samplerequire === 3) {
        Boxnumber = Boxnumber.map(item => `${item}`)
      } else {
      // 实际要抽总数
      // Samplecount = Samplebox * Eachboxcount
      // 最后一箱箱号
      // const lastBoxIndex = Boxnumber.length - 1
      // 按照间隔计算的每箱数量
      // Eachboxcount = Math.floor(Samplecount / Boxnumber.length)
      // const Oversample = Samplecount - (Eachboxcount * Boxnumber.length)
        Boxnumber = Boxnumber.map(item => `${item}/${Eachboxcount}`)
        Samplecount = Eachboxcount * Boxnumber.length
        Samplebox = Boxnumber.length
      }
      Boxnumberstr = Boxnumber.join('，')
    }
    if (nums !== '' && nums !== null && nums !== undefined && type === 3) {
      if (num !== '' && Samplebox !== '') {
        const result = []
        for (let i = 1; i <= num; i++) {
          result.push(i)
        }
        Boxnumber = result
      }
      Samplecount = nums
      Boxnumberstr = Boxnumber.map(item => `${item}`).join('，')
    }
    return {
    /**
   * 抽样总箱数
   */
      Samplebox,
      /**
   * 抽样总数
   */
      Samplecount,
      /**
   * 抽样间隔
   */
      Samplegap,
      /**
   * 抽样箱号数组
   */
      Boxnumber,
      /**
   * 抽样箱号数组拼接字符
   */
      Boxnumberstr
    }
  },
  /**
 * 包装材料抽样比例, 返回抽样箱数
 * @param {*} row  箱数
 * @param {*} row2 入库数
 */
  Getbaozhuangchouyangfun (row, row2) {
    if (row <= 3) {
    // 全开箱随机抽取样本检验
    } else if (row <= 15) {
    // 抽3箱随机抽取样本检验
    } else if (row <= 35) {
    // 抽5箱随机抽取样本检验
    } else if (row > 36) {
    // 抽7箱随机抽取样本检验
    }
  },
  Sampling_typefun: function (row) {
    var jurisdiction
    switch (row) {
      case 0:
        jurisdiction = i18n.t('IQC.正常')
        break
      case 1:
        jurisdiction = i18n.t('IQC.加严')
        break
      case 2:
        jurisdiction = i18n.t('IQC.减量')
        break
      case 3:
        jurisdiction = i18n.t('IQC.全检')
        break
    }
    return jurisdiction
  }
}
