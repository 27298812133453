<template>
  <div>
    <div style="text-align: right;z-index: 2;">
      <el-button size="small" style="text-align: right;" type="primary" @click="isqpisboot = !isqpisboot">{{ isqpisboot ?
        $t('All.取消全屏') : $t('All.全屏')}}</el-button>
      <el-button size="small" type="warning" v-if="!isdata" @click="klfun()">{{ this.$t('All.克隆') }}</el-button>
      <el-button size="small" style="text-align: right;" v-if="isdata" type="primary" @click="IpqcAdminadd">{{
        $t('All.提交') }}</el-button>
      <el-button size="small" style="text-align: right;" v-if="!isdata" type="primary" @click="IpqcAdminup">{{
        $t('All.更新') }}</el-button>
      <el-button size="small" type="primary" @click="dialogVisible = true">{{ this.$t('system.fjian') }}</el-button>
      <el-button size="small" type="danger" v-if="!isdata" @click="IpqcAdmindel">{{ this.$t('system.del') }}</el-button>
    </div>
    <div :class="isqpisboot ? 'wbobye zhadminadd' : 'zhadminadd'" id="printTest">
      <h2 style="text-align: center;">{{ this.$t('system.company') }}</h2>
      <h3 style="text-align: center;">{{ $t('All.生产巡检报表') }}</h3>
      <h4 style="text-align: center;">{{ $t('All.录入人') }}：{{ this.form.Entered }}</h4>
      <div style="text-align: right;padding-right: 10px; ">
        {{ $t('All.料号') }}：<el-input onkeyup="value=value.replace(/[^a-zA-Z0-9]/g,'').toUpperCase()"
          @blur="form.Item_no = form.Item_no.replace(/[^a-zA-Z0-9]/g, '').toUpperCase()"
          oninput="if (value.trim() === '') { value = null; }"
          style="width: 300px;border: 1px solid #DCDFE6;border-radius: 5px;" v-model="form.Item_no"></el-input>
      </div>
      <div style=" padding: 10px; text-align: center; line-height: 2vw;">
        <table border="1" style="width:100%; border-bottom: none;" align="center" cellpadding="10" cellspacing="0">
          <tr>
            <td colspan="2" class="tdd">{{ $t('All.班别') }}</td>
            <td colspan="2" class="tdd">{{ $t('All.制令单号') }}</td>
            <td colspan="2" class="tdd">{{ $t('All.客户') }}</td>
            <td colspan="2" class="tdd">{{ $t('All.品名') }}</td>
            <td colspan="2" class="tdd">{{ $t('All.测试机') }}</td>
            <td colspan="2" class="tdd">{{ $t('All.XRF送测编号') }}</td>
            <td colspan="2" class="tdd">{{ $t('All.ICP送测编号') }}</td>
            <td colspan="2" class="tdd" style="width:30px;">{{ $t('All.首件判定') }}</td>
          </tr>
          <tr>
            <td colspan="2" class="tdd">
              {{ form.Class }}
            </td>
            <td colspan="2" class="tdd"><el-input v-model="form.Order_no"></el-input></td>
            <td colspan="2" class="tdd"><el-input v-model="form.Customer"></el-input></td>
            <td colspan="2" class="tdd"><el-input v-model="form.Product_name"></el-input></td>
            <td colspan="2" class="tdd"><el-input v-model="form.Test_machine"></el-input></td>
            <td colspan="2" class="tdd"><el-input v-model="form.Xrfnumber"></el-input></td>
            <td colspan="2" class="tdd"><el-input v-model="form.Icpnumber"></el-input></td>
            <td colspan="2" class="tdd"><el-input v-model="form.First_article"></el-input></td>
          </tr>
          <tr>
            <td colspan="2" class="tdd">LOT NO.</td>
            <td colspan="2" class="tdd">{{ $t('All.SOP编号') }}</td>
            <td colspan="2" class="tdd">{{ $t('FQC.外观') }}</td>
            <td colspan="2" class="tdd">{{ $t('All.颜色') }}</td>
            <td colspan="2" class="tdd">{{ $t('All.色样号') }}</td>
            <td colspan="2" class="tdd">{{ $t('All.图面编号') }}</td>
            <td colspan="2" class="tdd">{{ $t('FQC.版本') }}</td>
            <td colspan="2" class="tdd">{{ $t('All.装箱数量') }}</td>
          </tr>
          <tr>
            <td colspan="2" class="tdd"><el-input v-model="form.Lot_no"></el-input></td>
            <td colspan="2" class="tdd"><el-input v-model="form.Drawing_no"></el-input></td>
            <td colspan="2" class="tdd">
              <el-select v-model="form.Appearance" :placeholder="$t('All.请判定')">
                <el-option label="Pass" value="Pass"></el-option>
                <el-option label="Fail" value="Fail"></el-option>
              </el-select>
            </td>
            <td colspan="2" class="tdd">
              <el-select v-model="form.Colour" allow-create filterable :placeholder="$t('All.请选择')">
                <el-option :label="$t('All.黑')" :value="$t('All.黑')"></el-option>
                <el-option :label="$t('All.白')" :value="$t('All.白')"></el-option>
                <el-option :label="$t('All.橙')" :value="$t('All.橙')"></el-option>
                <el-option :label="$t('All.黄')" :value="$t('All.黄')"></el-option>
                <el-option :label="$t('All.绿')" :value="$t('All.绿')"></el-option>
                <el-option :label="$t('All.青')" :value="$t('All.青')"></el-option>
                <el-option :label="$t('All.蓝')" :value="$t('All.蓝')"></el-option>
                <el-option :label="$t('All.紫')" :value="$t('All.紫')"></el-option>
              </el-select>
            </td>
            <td colspan="2" class="tdd"><el-input v-model="form.Color_sample_no"></el-input></td>
            <td colspan="2" class="tdd"><el-input v-model="form.Sopnumber"></el-input></td>
            <td colspan="2" class="tdd"><el-input v-model="form.Edition"></el-input></td>
            <td colspan="2" class="tdd"><el-input v-model="form.Packing_quantity"></el-input></td>
          </tr>
          <tr>
            <td class="tdd">{{ $t('All.量测仪器') }}/{{ $t('All.编号') }}</td>
            <td colspan="8"><el-input v-model="form.Measuring_instrument_no"></el-input></td>
            <td class="tdd">{{ $t('All.线位') }}</td>
            <td colspan="6"><el-input v-model="form.Line_position"></el-input></td>
          </tr>
          <tr>
            <td colspan="20"><span style="letter-spacing: 56px;">{{ $t('All.巡检记录') }}</span></td>
          </tr>
        </table>
        <table border="1" style="width:100%;border-bottom: none;" v-for="(items, indexx) in form.Patrol_records"
          :key="indexx" align="center" cellpadding="10" cellspacing="0">
          <tr id="dateid">
            <td colspan="10">
              <template>
                <el-time-picker :editable="false" v-model="items.Date1" :placeholder="$t('All.开始时间点')">
                </el-time-picker>
                {{ $t('All.至') }}
                <el-time-picker :editable="false" v-model="items.Date2" :placeholder="$t('All.结束时间点')">
                </el-time-picker>
              </template>
            </td>
            <td colspan="2" class="tdd">{{ $t('All.首件') }}</td>
            <td colspan="2" class="tdd">
              <el-select style="width: 50px;" allow-create filterable v-model="items.Ispiece"
                :placeholder="$t('All.选择')">
                <el-option :label="$t('All.是')" :value="1"></el-option>
                <el-option :label="$t('All.否')" :value="0"></el-option>
              </el-select>
            </td>
            <td colspan="4">
              <el-button style="margin-left: 11px;" size="mini" type="primary" icon="el-icon-plus"
                @click="addDomains(items, indexx)"></el-button>
              <el-button v-show="form.Patrol_records.length > 1" size="mini" type="danger" icon="el-icon-delete"
                @click="removeDomain(items)"></el-button>
            </td>
          </tr>
          <tr>
            <td rowspan="2" colspan="1" class="tdd">{{ $t('All.工序名巡检项目') }}</td>
            <td rowspan="2" colspan="2" class="tdd">{{ $t('All.规格值') }}{{ $t('All.要求') }}</td>
            <td rowspan="2" colspan="1" style="width: 60px;" class="tdd">{{ $t('All.单位') }}</td>
            <td colspan="10" class="tdd">{{ $t('All.实际值') }}</td>
            <td rowspan="2" class="tdd">{{ $t('All.实际值') }}</td>
            <td rowspan="2" colspan="3" style="width: 170px;" class="tdd">{{ $t('All.是否按SOP要求作业') }}</td>
          </tr>
          <tr>
            <td colspan="2" class="tdd">1</td>
            <td colspan="2" class="tdd">2</td>
            <td colspan="2" class="tdd">3</td>
            <td colspan="2" class="tdd">4</td>
            <td colspan="2" class="tdd">5</td>
          </tr>
          <tr v-for="(item, index) in items.Patrol " :key="index">
            <td colspan="1" class="tdd"><el-input v-model="item.Patrol_name"></el-input></td>
            <td colspan="2" class="tdd"><el-input v-model="item.Specification"></el-input></td>
            <td colspan="1" style="width: 50px;" class="tdd">
              <el-select style="width: 50px;" allow-create filterable v-model="item.Company"
                :placeholder="$t('All.选择')">
                <el-option label="MM" value="MM"></el-option>
                <el-option label="CM" value="CM"></el-option>
                <el-option label="KG" value="kG"></el-option>
                <el-option label="G" value="G"></el-option>
                <el-option label="PC" value="PC"></el-option>
                <el-option label="°C" value="°C"></el-option>
              </el-select>
            </td>
            <td colspan="2" class="tdd"><textarea disabled class="el-input__inner"
                style="display: block;resize: none;border-radius: unset;font-size: 14px;" rows="1"
                v-model.lazy="item.Value1"></textarea></td>
            <td colspan="2" class="tdd"><textarea disabled class="el-input__inner"
                style="display: block;resize: none;border-radius: unset;font-size: 14px;" rows="1"
                v-model.lazy="item.Value2"></textarea></td>
            <td colspan="2" class="tdd"><textarea disabled class="el-input__inner"
                style="display: block;resize: none;border-radius: unset;font-size: 14px;" rows="1"
                v-model.lazy="item.Value3"></textarea></td>
            <td colspan="2" class="tdd"><textarea disabled class="el-input__inner"
                style="display: block;resize: none;border-radius: unset;font-size: 14px;" rows="1"
                v-model.lazy="item.Value4"></textarea></td>
            <td colspan="2" class="tdd"><textarea disabled class="el-input__inner"
                style="display: block;resize: none;border-radius: unset;font-size: 14px;" rows="1"
                v-model.lazy="item.Value5"></textarea></td>
            <td colspan="1" style="width: 85px;" class="tdd">
              <el-select style="width: 50px;" v-model="item.Result" :placeholder="$t('All.请判定')">
                <el-option label="Pass" value="Pass"></el-option>
                <el-option label="Fail" value="Fail"></el-option>
              </el-select>
            </td>
            <td colspan="3" class="tdd">
              <el-select v-model="item.Whether" :placeholder="$t('All.请选择')"
                @change="addlist(indexx, item, index, item.Whether)">
                <el-option :label="$t('All.是')" :value="$t('All.是')"></el-option>
                <el-option :label="$t('All.否')" :value="$t('All.否')"></el-option>
                <el-option :label="$t('All.增加')" :value="$t('All.增加')"></el-option>
                <el-option :disabled="items.Patrol.length == 1" :label="$t('All.删除')" :value="$t('All.删除')"></el-option>
              </el-select>
            </td>
          </tr>
        </table>
        <table border="1" style="width:100%;border-bottom: none;" align="center" cellpadding="10" cellspacing="0">
          <tr>
            <td class="tdd">{{ $t('All.备注') }}</td>
            <td colspan="3" class="tdd"><el-input class="tdd" style="word-wrap:break-word;text-align: left;" :rows="3"
                type="textarea" v-model="form.Beizhu"></el-input></td>
          </tr>
          <tr v-for="(item, index) in form.Beilist" :key="index">
            <td class="tdd">{{ $t('All.备注') }}{{ index }}</td>
            <td class="tdd"><textarea class="el-input__inner"
                style="display: block;resize: none;border-radius: unset;font-size: 14px;" rows="1"
                v-model.lazy="item.Title"></textarea></td>
            <td class="tdd" style="word-wrap:break-word;text-align: left;"><textarea class="el-input__inner"
                style="display: block;resize: none;border-radius: unset;font-size: 14px;" rows="1"
                v-model.lazy="item.Txte"></textarea></td>
            <td class="tdd" style="width: 120px;">
              <el-button size="mini" type="primary" @click="addbeizhulist(item, index)">Add</el-button>
              <el-button size="mini" type="danger" v-show="form.Beilist.length > 1"
                @click="removebeizhulist(item, index)">Del</el-button>
            </td>
          </tr>
        </table>
        <table border="1" style="width:100%;" align="center" cellpadding="10" cellspacing="0">
          <tr>
            <td class="tdd">{{ $t('All.备注') }}</td>
            <td class="tdd" style="word-wrap:break-word;text-align: left;">{{ this.$t('All.固定备注') }}</td>
          </tr>
        </table>
        <el-row>
          <el-col :span="12" style="border: 0px solid transparent;"><span
              style="font-size: 1.5vw;">{{ this.$t('FQC.核准') }}:
              {{ form.Hz }}</span></el-col>
          <el-col :span="12" style="border: 0px solid transparent;"><span
              style="font-size: 1.5vw;">{{ this.$t('FQC.检验员') }}:
              {{ form.Jyy }}</span></el-col>
        </el-row>
        <el-row>
          <el-col :span="12" style="border: 0px solid transparent;">
            <h6></h6>
          </el-col>
          <el-col :span="12" style="border: 0px solid transparent;padding-right: 20px;text-align: right;"><span
              style="font-size: 1.5vw;">NYP1002-02 B</span></el-col>
        </el-row>
      </div>
    </div>
    <el-dialog :title="this.$t('system.fjian')" :visible.sync="dialogVisible" width="80%" :before-close="handleClose"
      append-to-body>
      <fqcUpload :isdata="isdata" :fqcadmin="form.Number"></fqcUpload>
    </el-dialog>
    <el-dialog :title="this.$t('All.克隆')" :visible.sync="dialogVisiblekl" width="500px" :before-close="handleClose"
      append-to-body>
      <el-form :model="klform">
        <el-form-item :label="$t('All.模版单号')">
          {{ klform.Number }}
        </el-form-item>
        <el-form-item :label="$t('All.克隆后的料号')">
          <el-input v-model="klform.Item_no" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisiblekl = false">{{ $t('All.取消') }}</el-button>
        <el-button type="primary" @click="klIQC_Admin()">{{ $t('All.确定') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
import fqcUpload from './Upload/IPQCAdminUpload'
export default {
  data () {
    return {
      form: {
        Number: '',
        Timsdate: '',
        Class: '',
        Order_no: '',
        Customer: '',
        Sopnumber: '',
        Edition: '',
        Xrfnumber: '',
        Icpnumber: '',
        First_article: '',
        Lot_no: '',
        Drawing_no: '',
        Appearance: '',
        Colour: '',
        Color_sample_no: '',
        Test_machine: '',
        Line_position: '',
        Packing_quantity: '',
        Measuring_instrument_no: '',
        Product_name: '',
        Beizhu: '',
        Item_no: '',
        Patrol_records: [
          {
            Numbers: '',
            Number: '',
            Index: 0,
            Date1: '',
            Date2: '',
            Ispiece: 0,
            Isbool: 1,
            Patrol: [
              {
                Cid: 0,
                Numbers: '',
                Number: '',
                Index: 0,
                Patrol_name: '',
                Specification: '',
                Company: '',
                Value1: '',
                Value2: '',
                Value3: '',
                Value4: '',
                Value5: '',
                Result: '',
                Whether: ''
              }
            ]
          }
        ],
        Beilist: [
          {
            Numbers: '',
            Number: '',
            Index: 0,
            Title: '',
            Txte: ''
          }
        ],
        Examination: '',
        Auditor: '',
        Entered: '',
        Factory: ''
      },
      isqpisboot: false,
      isdata: false,
      dialogVisible: false,
      klform: {},
      dialogVisiblekl: false
    }
  },
  props: ['Addtab', 'RemoveTab', 'Renewtargename', 'Rendw'],
  created () {
    if (this.$store.state.UserData === null) {
      this.QcCount()
      this.isdata = true
      this.form.Entered = this.$store.state.Login.Username
      this.form.Factory = this.$store.state.Login.Factory
      this.form.Item_no = this.$store.state.Productname
    } else {
      this.isdata = false
      this.Getipqcadmin(this.$store.state.UserData.Id)
    }
  },
  components: {
    fqcUpload
  },
  mounted () {

  },
  methods: {
    ...mapMutations(['TabComponentFun']),
    async QcCount () {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        loadingInstance.close()
        this.form.Number = this.$store.state.IPQCadminnumber
        this.form.Patrol_records[0].Number = this.$store.state.IPQCadminnumber
        this.form.Patrol_records[0].Patrol[0].Number = this.$store.state.IPQCadminnumber
        this.form.Beilist[0].Number = this.$store.state.IPQCadminnumber
        for (let index = 0; index < 20; index++) {
          this.addlist(0, this.form.Patrol_records[0].Patrol[0], index, this.$t('All.增加'))
        }
      } catch (error) {
        loadingInstance.close()
        alert(this.$t('All.网络异常'))
      }
    },
    handleClose (done) {
      this.$confirm(this.$t('All.确认关闭'), {
        confirmButtonText: this.$t('All.确认'),
        cancelButtonText: this.$t('All.取消'),
        type: 'warning'
      })
        .then(_ => {
          done()
        })
        .catch(_ => { })
    },
    addbeizhulist (item, index) {
      const contr = {
        Number: item.Number,
        Numbers: '',
        Index: item.Index + 1,
        Title: '',
        Txte: ''
      }
      this.form.Beilist.splice(index + 1, 0, contr)
      for (let i = 0; i < this.form.Beilist.length; i++) {
        this.form.Beilist[i].Index = i
      }
    },
    removebeizhulist (item) {
      this.$confirm(this.$t('All.是否删除此节点'), this.$t('All.提示'), {
        confirmButtonText: this.$t('All.确认'),
        cancelButtonText: this.$t('All.取消'),
        type: 'warning'
      })
        .then(() => {
          var index = this.form.Beilist.indexOf(item)
          if (index !== -1) {
            this.form.Beilist.splice(index, 1)
          }
          for (let i = 0; i < this.form.Beilist.length; i++) {
            this.form.Beilist[i].Index = i
          }
        })
        .catch(() => {
        })
    },
    addDomains (item, index) {
      const contr = {
        Number: item.Number,
        Numbers: '',
        Index: item.Index + 1,
        Date1: '',
        Date2: '',
        Ispiece: 0,
        Isbool: 1,
        Patrol: JSON.parse(JSON.stringify(item.Patrol))
      }
      contr.Patrol.forEach(element => {
        element.Cid = contr.Index
        element.Value1 = ''
        element.Value2 = ''
        element.Value3 = ''
        element.Value4 = ''
        element.Value5 = ''
        element.Result = ''
        element.Whether = ''
      })
      this.form.Patrol_records.splice(index + 1, 0, contr)
      for (let i = 0; i < this.form.Patrol_records.length; i++) {
        this.form.Patrol_records[i].Index = i
        this.form.Patrol_records[i].Patrol.forEach(element => {
          element.Cid = this.form.Patrol_records[i].Index
        })
      }
    },
    removeDomain (item) {
      this.$confirm(this.$t('All.是否删除此节点'), this.$t('All.提示'), {
        confirmButtonText: this.$t('All.确认'),
        cancelButtonText: this.$t('All.取消'),
        type: 'warning'
      })
        .then(() => {
          var index = this.form.Patrol_records.indexOf(item)
          if (index !== -1) {
            this.form.Patrol_records.splice(index, 1)
          }
          for (let i = 0; i < this.form.Patrol_records.length; i++) {
            this.form.Patrol_records[i].Index = i
            this.form.Patrol_records[i].Patrol.forEach(element => {
              element.Cid = i
            })
          }
        })
        .catch(() => {
        })
    },
    addlist (indexx, item, index, value) {
      if (value === this.$t('All.增加')) {
        this.addDomainlist(indexx, item, index)
        this.form.Patrol_records[indexx].Patrol[index].Whether = ''
      }
      if (value === this.$t('All.删除')) {
        this.$confirm(this.$t('All.是否删除此节点'), this.$t('All.提示'), {
          confirmButtonText: this.$t('All.确认'),
          cancelButtonText: this.$t('All.取消'),
          type: 'warning'
        })
          .then(() => {
            this.removeDomainlist(item, indexx)
          })
          .catch(() => {
            this.form.Patrol_records[indexx].Patrol[index].Whether = ''
          })
      }
    },
    addDomainlist (indexx, item, index) {
      const contr = {
        Number: item.Number,
        Numbers: '',
        Cid: item.Cid,
        Index: 1,
        Patrol_name: item.Patrol_name,
        Specification: item.Specification,
        Company: item.Company,
        Value1: '',
        Value2: '',
        Value3: '',
        Value4: '',
        Value5: '',
        Result: '',
        Whether: ''
      }
      this.form.Patrol_records[indexx].Patrol.splice(index + 1, 0, contr)
      for (let i = 0; i < this.form.Patrol_records[indexx].Patrol.length; i++) {
        this.form.Patrol_records[indexx].Patrol[i].Index = i
        this.form.Patrol_records[indexx].Patrol[i].Cid = this.form.Patrol_records[indexx].Index
      }
    },
    removeDomainlist (item, indexx) {
      var index = this.form.Patrol_records[indexx].Patrol.indexOf(item)
      if (index !== -1) {
        this.form.Patrol_records[indexx].Patrol.splice(index, 1)
      }
      for (let i = 0; i < this.form.Patrol_records[indexx].Patrol.length; i++) {
        this.form.Patrol_records[indexx].Patrol[i].Index = i
      }
    },
    async IpqcAdminadd () {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post('/api/Ipqc/IpqcAdminadd', this.form)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(res.msg)
        }
        loadingInstance.close()
        this.$message.success(res.msg)
        this.Renewtargename(this.$t('All.IPQC模版'), 'IPQCAdminData', 'IPQCAdminData')
        this.RemoveTab(this.$t('All.IPQC模版') + '-' + this.form.Number)
      } catch (error) {
        this.$message.error(this.$t('All.服务器断开'))
        loadingInstance.close()
      }
    },
    async IpqcAdmindel () {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post('/api/Ipqc/IpqcAdmindel', this.form)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(this.$t('All.数据异常'))
        }
        loadingInstance.close()
        this.$message.success(res.msg)
        this.Renewtargename(this.$t('All.IPQC模版'), 'IPQCAdminData', 'IPQCAdminData')
        this.RemoveTab(this.$t('All.IPQC模版') + '-' + this.form.Number)
      } catch (error) {
        this.$message.error(this.$t('All.服务器断开'))
        loadingInstance.close()
      }
    },
    async IpqcAdminup () {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post('/api/Ipqc/IpqcAdminup', this.form)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(this.$t('All.数据异常'))
        }
        loadingInstance.close()
        this.$message.success(res.msg)
        this.Renewtargename(this.$t('All.IPQC模版'), 'IPQCAdminData', 'IPQCAdminData')
        this.RemoveTab(this.$t('All.IPQC模版') + '-' + this.form.Number)
      } catch (error) {
        this.$message.error(this.$t('All.服务器断开'))
        loadingInstance.close()
      }
    },
    async klfun () {
      this.klform = JSON.parse(JSON.stringify(this.form))
      this.klform.Item_no = ''
      this.klform.Entered = this.$store.state.Login.Username
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      const QcCountLogin = {
        Login: this.$store.state.Login,
        Type: 'IPQC'
      }
      const { data: res } = await this.$http.post('/api/User/QcCount', QcCountLogin)
      if (res.status !== 200) {
        loadingInstance.close()
        return this.$message.error(res.response)
      }
      loadingInstance.close()
      this.klform.Number = res.response
      for (let index = 0; index < this.klform.Patrol_records.length; index++) {
        this.klform.Patrol_records[index].Number = this.klform.Number
        for (let j = 0; j < this.klform.Patrol_records[index].Patrol.length; j++) {
          this.klform.Patrol_records[index].Patrol[j].Number = this.klform.Number
        }
      }
      for (let index = 0; index < this.klform.Beilist.length; index++) {
        this.klform.Beilist[index].Number = this.klform.Number
      }
      this.dialogVisiblekl = true
    },
    async klIQC_Admin () {
      if (this.klform.Item_no.length < 1) return this.$message.error(this.$t('All.请填写料号后在提交'))
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post('/api/Ipqc/IpqcAdminadd', this.klform)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(res.msg)
        }
        loadingInstance.close()
        this.$message.success(res.msg)
        this.Renewtargename(this.$t('All.IPQC模版'), 'IPQCAdminData', 'IPQCAdminData')
        this.dialogVisiblekl = false
      } catch (error) {
        this.$message.error(error)
        loadingInstance.close()
      }
    },
    async Getipqcadmin (id) {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      const { data: res } = await this.$http.get(`/api/Ipqc/Getipqcadmin?Id=${id}`)
      loadingInstance.close()
      if (res.status !== 200) return this.$message.error(res.msg)
      this.form = res.response
      if (this.form.Beilist.length === 0) {
        this.form.Beilist = [{
          Numbers: this.form.Numbers,
          Number: this.form.Number,
          Index: 0,
          Title: '',
          Txte: ''
        }]
      }
      if (this.form.Patrol_records.length === 0) {
        this.form.Patrol_records = [
          {
            Numbers: this.form.Numbers,
            Number: this.form.Number,
            Index: 0,
            Date1: '',
            Date2: '',
            Ispiece: 0,
            Isbool: 1,
            Patrol: [
              {
                Cid: 0,
                Numbers: this.form.Numbers,
                Number: this.form.Number,
                Index: 0,
                Patrol_name: '',
                Specification: '',
                Company: '',
                Value1: '',
                Value2: '',
                Value3: '',
                Value4: '',
                Value5: '',
                Result: '',
                Whether: ''
              }
            ]
          }
        ]
      }
    }
  }
}
</script>
<style lang="less" scoped>
.wbobye {
  position: absolute/fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255);
}

.el-col {
  border: 1px solid;
  margin-right: -1px;
  margin-bottom: -1px;
  line-height: 4.5vw;
  font-size: 2vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.grid-content {
  min-height: 36px;
}

.bg-purple-light {}

.row-bg {
  background-color: #f9fafc;
}

table .td {
  border: none;
}
</style>
