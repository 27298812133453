<template>
    <el-upload list-type="picture-card" :disabled="isdata" :action="`${$Upload}/api/User/ImgRightside/`" :data="filedata" :on-success="handsuccess" :file-list="fileList">
        <i slot="default" class="el-icon-plus"></i>
        <div slot="file" slot-scope="{file}">
            <img class="el-upload-list__item-thumbnail" :src="xsimg(file)" :alt="file.name">
            <span class="el-upload-list__item-actions" :alt="file.name">
                <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                    <i class="el-icon-zoom-in"></i>
                </span>
                <span class="el-upload-list__item-delete" @click="handleDownload(file)">
                    <i class="el-icon-download"></i>
                </span>
                <span v-if="!isdata && file.del" class="el-upload-list__item-delete" @click="handleRemove(file)">
                    <i class="el-icon-delete"></i>
                </span>
                <span>
                  <i class="elemzit">{{file.name}}</i>
                </span>
            </span>
        </div>
        <el-dialog :visible.sync="dialogVisible" append-to-body>
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
    </el-upload>
</template>
<script>
export default {
  data () {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      fileList: [],
      filedata: {
        Workorder: this.fqcnumerb,
        User: this.$store.state.Login.User,
        Username: this.$store.state.Login.Username,
        Factory: this.$store.state.Login.Factory
      }
    }
  },
  props: ['isdata', 'fqcadmin', 'fqcnumerb'], // 接手psMsg值
  created () {
    this.GetAllFilesuser(this.fqcnumerb)
  },
  mounted () {
  },
  methods: {
    async handleRemove (file) {
      if (!file.del) {
        return this.$message.error(this.$t('All.录入附件不可删除'))
      }
      const loadingInstance = this.$loading({ text: this.$t('All.拼命加载中'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        var row = {
          name: file.name,
          url: file.url,
          Workorder: this.filedata.Workorder
        }
        const { data: res } = await this.$http.post('/api/User/ImgDel', row)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(this.$t('All.数据异常'))
        }
        loadingInstance.close()
        this.$message.success(`${this.$t('All.删除')}${file.name}${this.$t('All.成功')}`)
        this.fileList.some((item, i) => {
          if (item.name === file.name) {
            this.fileList.splice(i, 1)
          }
        })
      } catch (error) {
        this.$message.error(error)
        loadingInstance.close()
      }
    },
    handlePictureCardPreview (file) {
      var index = file.name.lastIndexOf('.')
      const obj = file.name.substring(index + 1, file.name.length)
      const objj = obj.toUpperCase()
      switch (objj) {
        case 'JPEG':
          this.dialogImageUrl = file.url
          this.dialogVisible = true
          break
        case 'BMP':
          this.dialogImageUrl = file.url
          this.dialogVisible = true
          break
        case 'TIFF':
          this.dialogImageUrl = file.url
          this.dialogVisible = true
          break
        case 'GIF':
          this.dialogImageUrl = file.url
          this.dialogVisible = true
          break
        case 'PNG':
          this.dialogImageUrl = file.url
          this.dialogVisible = true
          break
        case 'JPG':
          this.dialogImageUrl = file.url
          this.dialogVisible = true
          break
        case 'PDF':
          return this.$message.error(this.$t('All.暂不支持预览PDF'))
        case 'XLS':
          return this.$message.error(this.$t('All.暂不支持预览Excel'))
        case 'XLSX':
          return this.$message.error(this.$t('All.暂不支持预览Excel'))
        case 'RAR':
          return this.$message.error(this.$t('All.暂不支持预览压缩文件'))
        case 'ZIP':
          return this.$message.error(this.$t('All.暂不支持预览压缩文件'))
        default:
          return this.$message.error(this.$t('All.无法识别文件类型不支持预览'))
      }
    },
    handleDownload (file) {
      window.open(file.url, '_blank')
    },
    xsimg (val) {
      var index = val.name.lastIndexOf('.')
      const obj = val.name.substring(index + 1, val.name.length)
      const objj = obj.toUpperCase()
      switch (objj) {
        case 'JPEG':
          return val.url
        case 'BMP':
          return val.url
        case 'TIFF':
          return val.url
        case 'GIF':
          return val.url
        case 'PNG':
          return val.url
        case 'JPG':
          return val.url
        case 'PDF':
          return require('@/assets/Img/pdf.jpg')
        case 'XLS':
          return require('@/assets/Img/excle.jpg')
        case 'XLSX':
          return require('@/assets/Img/excle.jpg')
        case 'RAR':
          return require('@/assets/Img/rar.jpeg')
        case 'ZIP':
          return require('@/assets/Img/rar.jpeg')
        default:
          return require('@/assets/Img/nullimg.jpeg')
      }
    },
    async handsuccess (response) {
      const data = {
        name: '',
        url: '',
        del: true
      }
      if (response.status !== 200) return this.$message.error(this.$t('All.图片上传失败'))
      data.name = response.name
      data.url = response.url
      this.fileList.push(data)
    },
    async GetAllFilesuser (row) {
      try {
        const { data: res } = await this.$http.get('/api/User/FQCGetAllFiles?Workorder=' + row + '&Name=' + this.fqcadmin + '&Factory=' + this.$store.state.Login.Factory)
        if (res.status !== 200) return
        this.fileList = res.response
      } catch (error) {
        alert(error)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.elemzit {
  font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
  font-size: 16px;
  font-style: normal;
}
</style>
